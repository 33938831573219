<template>
  <div class="content p-0">
    <div class="login-wrapper">
      <div class="row ">
        <div class="col-lg-4 side-banner">
            <div class="row">
              <div class="col-md-12"  style="background-image:url('/static/images/bg-login.jpg');background-position: center center; background-size: cover; height:50vh"> 
                <div class="content px-4 text-center d-flex justify-content-center ">
                  <div class="align-self-center">
                    <h2 class="text-white">
                      Thriggle
                    </h2>
                    <h4 class="text-white">
                    Plan Your Night Out
                    </h4>
                    <p class="text-white">
                    Discover Events and entertainment in your area
                    </p>
                  </div>
                </div>
              </div>
             
               <div class="col-md-12 "  style="background-image:url('/static/images/zcp.png');background-size: cover; height:50vh"> 
                <div class="content px-4 text-center d-flex justify-content-center ">
                  <div class="align-self-center">
                    <h2 class="text-white">
                     ZIP Code Pros
                    </h2>
                    <h4 class="text-white">
                   Connect With A Professional
                    </h4>
                    <p class="text-white">
                   Find Service Professionals closeby
                    </p>
                    <!-- <a href="JavaScript:Void(0)" class="btn btn-success">Search Now</a> -->
                  </div>
              </div>
              </div>

            </div>
         
        </div>
        


        
        <div class="col-lg-8 site-content">
          <div class="content">
            <div class="row">
              <div class="col-lg-7 mx-auto">
                <div class="text-center mb-4">
                  <h2>Create Account.</h2>
                  <ul
                    id="myTab"
                    class="nav nav-tabs"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <a
                        id="home-tab"
                        class="nav-link active"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >USER SIGN-UP</a>
                    </li>
                    <li class="nav-item">
                      <a
                        id="profile-tab"
                        class="nav-link"
                        data-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >ADVERTISER SIGN-UP</a>
                    </li>
                  </ul>
                  <div
                    id="myTabContent"
                    class="tab-content"
                  >
                    <div
                      id="home"
                      class="tab-pane fade show active"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div class="card bg-secondary shadow border-0 mt-4">
                         <span class="font-weight-bold" style="font-size:20px;color:#0f7acf"> Thriggle and ZIP Code Pros </span> 
                        <span class="font-weight-bold" style="font-size:18px;">Events and Services in neighbourhood</span>
                        
                        <div class="card-body px-lg-5 py-lg-5">
                          <form role="form">
                            <div class="form-group mb-3">
                              <div class="input-group input-group-alternative">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"><i class="fa fa-user" /></span>
                                </div>
                                <input
                                  v-model="obj.user_name"
                                  class="form-control"
                                  placeholder="Name"
                                  type="text"
                                >
                              </div>
                              <small class="text-danger font-weight-bold"> {{ errors.user_name }}</small>
                            </div>
                            <div class="form-group mb-3">
                              <div class="input-group input-group-alternative">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"><i class="fa fa-envelope" /></span>
                                </div>
                                <input
                                  v-model="obj.email"
                                  class="form-control"
                                  placeholder="Email"
                                  type="email"
                                >
                              </div>
                              <small class="text-danger font-weight-bold"> {{ errors.email }}</small>
                            </div>
                            <div class="form-group">
                              <div class="input-group input-group-alternative">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"><i class="fa fa-unlock-alt" /></span>
                                </div>
                                <input
                                  v-model="obj.password"
                                  class="form-control"
                                  placeholder="Password"
                                  type="password"
                                >
                              </div>
                              <small class="text-danger font-weight-bold"> {{ errors.password }}</small>
                            </div>
                            <div class="text-center">
                              <button
                                type="button"
                                class="btn btn-primary mt-4"
                                @click="submit"
                              >
                                Register
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <!-- Advertiser signup  -->
                    <div
                      id="profile"
                      class="tab-pane fade"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div class="card bg-secondary shadow border-0 mt-4">
                       <span class="font-weight-bold" style="font-size:20px;color:#0f7acf"> Thriggle and ZIP Code Pros </span> 
                        <span class="font-weight-bold" style="font-size:18px;">Signup for free below</span>
                        <div class="card-body px-lg-5 py-lg-5">
                          <form role="form">
                            <div class="form-group mb-3">
                              <div class="input-group input-group-alternative">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"><i class="fa fa-building" /></span>
                                </div>
                                <input
                                  v-model="advertiser.business_name"
                                  class="form-control"
                                  placeholder="Business Name"
                                  type="text"
                                >
                              </div>
                              <small class="text-danger font-weight-bold"> {{ errors.advertiser_business_name }}</small>
                            </div>
                            <div class="form-group mb-3">
                              <div class="input-group input-group-alternative">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"><i class="fa fa-map-marker" /></span>
                                </div>
                                <input
                                  v-model="advertiser.address"
                                  class="form-control"
                                  placeholder="Address"
                                  type="text"
                                >
                              </div>
                              <small class="text-danger font-weight-bold"> {{ errors.advertiser_address }}</small>
                            </div>
                            <div class="form-group mb-3">
                              <div class="input-group input-group-alternative">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"><i class="fa fa-map-marker" /></span>
                                </div>
                                <input
                                  v-model="advertiser.zipcode"
                                  class="form-control"
                                  placeholder="Zipcode"
                                  type="number"
                                >
                              </div>
                              <small class="text-danger font-weight-bold"> {{ errors.advertiser_zipcode }}</small>
                            </div>
                            <div class="form-group mb-3">
                              <div class="input-group input-group-alternative">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"><i class="fa fa-envelope" /></span>
                                </div>
                                <input
                                  v-model="advertiser.email"
                                  class="form-control"
                                  placeholder="Email"
                                  type="email"
                                >
                              </div>
                              <small class="text-danger font-weight-bold"> {{ errors.advertiser_email }}</small>
                            </div>
                            <div class="form-group">
                              <div class="input-group input-group-alternative">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"><i class="fa fa-unlock-alt" /></span>
                                </div>
                                <input
                                  v-model="advertiser.password"
                                  class="form-control"
                                  placeholder="Password"
                                  type="password"
                                >
                              </div>
                              <small class="text-danger font-weight-bold"> {{ errors.advertiser_password }}</small>
                            </div>
                            <div class="text-center">
                              <button
                                type="button"
                                class="btn btn-primary mt-4"
                                @click="submitAdvertiser"
                              >
                                Register
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <p>Fill up the following details</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {axios} from '../services/http-common';
export default {

	data(){
		return {
			obj:{
				user_name:'',
				email:'',
				password:'',
			},
			advertiser:{
				business_name:'',
				address:'',
				zipcode:'',
				email:'',
				password:'',
			},
			errors:{
				user_name:'',
				email:'',
				password:'',

				advertiser_business_name:'',
				advertiser_email:'',
				advertiser_password:'',
				advertiser_zipcode:'',
				advertiser_address:'',
			}
		}
	},

	methods:{
		submit(){
			 return axios
            .post( `${process.env.VUE_APP_API_URL}fans/signup`,this.obj)
            .then(response => {
				if(!response.error) {
				localStorage.setItem('token', response.data.message.token);
                window.location.href = "/";
				}
         	})
		 	.catch(error => {
				if(error.response.data['email'])
					this.errors.email = error.response.data['email'][0];
				if(error.response.data['user_name'])
					this.errors.user_name = error.response.data['user_name'][0];
				if(error.response.data['passsword'])
					this.errors.password = error.response.data['passsword'][0];

		 	});
		},
		submitAdvertiser(){
			 return axios
            .post( `${process.env.VUE_APP_API_URL}sponsors/signup`,this.advertiser)
            .then(response => {
				if(!response.error) {
				localStorage.setItem('token', response.data.message.token);
                window.location.href = "/";
				}
         	})
		 	.catch(error => {
				 console.log('error is :',error.response.data.errors['email']);
				if(error.response.data.errors['email'])
					this.errors.advertiser_email = error.response.data.errors['email'][0];
				if(error.response.data.errors['business_name'])
					this.errors.advertiser_business_name = error.response.data.errors['business_name'][0];
				if(error.response.data.errors['passsword'])
					this.errors.advertiser_password = error.response.data.errors['passsword'][0];
				if(error.response.data.errors['zipcode'])
					this.errors.advertiser_zipcode = error.response.data.errors['zipcode'][0];
				if(error.response.data.errors['address'])
					this.errors.advertiser_address = error.response.data.errors['address'][0];
		 	});
		}
	}

}
</script>
<style scoped>
.tab-content{
	padding:0 !important;
	
}
.black-overlay{
 position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}
</style>
